// overwrite transitions for the home-hero carousel to avoid bugs on safari.
// can't use inline CSS with vanilla js because -webkit and -moz don't quite work on safari with this approach

/* stylelint-disable property-no-vendor-prefix */
.main-home-hero-section ul,
.main-home-hero-section li {
  -webkit-transition: all 1.2s ease !important;
  -moz-transition: all 1.2s ease !important;
  -o-transition: all 1.2s ease !important;
  transition: all 1.2s ease !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* stylelint-enable property-no-vendor-prefix */
