/* Noto Sans, self hosted vs. direct from google fonts */
@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

/*
  For performance reason Achieve.com is direct hosting fonts vs. using through sunbeam/google api
  for improved performance and caching.
*/

/* bold */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/Ultramarine-Bold.woff2') format('woff2'),
    url('/fonts/Ultramarine-Bold.woff') format('woff');
}

/* bold italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/Ultramarine-BoldItalic.woff2') format('woff2'),
    url('/fonts/Ultramarine-BoldItalic.woff') format('woff');
}

/* italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Ultramarine-Italic.woff2') format('woff2'),
    url('/fonts/Ultramarine-Italic.woff') format('woff');
}

/* medium */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/Ultramarine-Medium.woff2') format('woff2'),
    url('/fonts/Ultramarine-Medium.woff') format('woff');
}

/* medium italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/Ultramarine-MediumItalic.woff2') format('woff2'),
    url('/fonts/Ultramarine-MediumItalic.woff') format('woff');
}

/* regular */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Ultramarine-Regular.woff2') format('woff2'),
    url('/fonts/Ultramarine-Regular.woff') format('woff');
}
